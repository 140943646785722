import React, {Component} from 'react';
import './App.css';
import FilterRadioGroup from './components/FilterRadioGroup';
import FilterNoRadioGroup from './components/FilterNoRadioGroup';
import FilterRadioIconGroup from './components/FilterRadioIconGroup';
import FilterRadioProductGroup from './components/FilterRadioProductGroup';
import SearchItem from './components/SearchItem';
import {firstBy} from "thenby";
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            filters: {
                global: '',
                productgroup: null,
                purpose: null,
                wiring: 'direct',
                color: 'white',
                shape: 'round',
                mounting: 'recessed',
                placement: 'ceiling'
                
            },
            data: []
        };

        this.filter = this.filter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.globalSearchHandler = this.globalSearchHandler.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        fetch('https://api.evaqlighting.com/api/products?filter={%22include%22:[%22mechanicalmodel%22,%22battery%22]}')
            .then(resp => resp.json())
            .then(data => {
                this.setState({data: data, ready: true});
            })
    }

    filter() {
        if(this.state.filters.global.length > 0) {
            // Filter globally, ignore filter groups
            return this.state.data.filter(item => {
                return (
                    item.fixture.toLowerCase().indexOf(this.state.filters.global) !== -1 ||
                    item.productNumber.toLowerCase().indexOf(this.state.filters.global) !== -1
                );
            })
        }
        else {
            // Use filter groups
            return this.state.data.filter(item => {
               // const placementSimplified = item.placement.toLowerCase();//.indexOf('pendel') === 0 ? 'pendel' : item.placement;
                
                
                return (
                    item.color === this.state.filters.color &&
                    item.shape === this.state.filters.shape &&
                    item.wiring === this.state.filters.wiring &&
                    item.mounting === this.state.filters.mounting &&
                    //placementSimplified === this.state.filters.placement &&
                    (this.state.filters.productgroup === null || item.productgroup === this.state.filters.productgroup) &&
                    (this.state.filters.purpose === null || item.purpose === this.state.filters.purpose)
                );
            })
        }
    }

    sort(data) {
        return [...data].sort(
            firstBy(function (a, b) {
                return a.fixture.localeCompare(b.fixture);
            })
            .thenBy(function (a, b) {
                return a.mounting.localeCompare(b.mounting);
            })
            .thenBy(function (a, b) {
                return a.feature.localeCompare(b.feature);
            })
        );
    }

    setFilter(key, value) {
        const filters = {...this.state.filters};
        filters[key] = value;

        // Reset the "Purpose" filter if we changed the product group
        if (key === 'productgroup') filters.purpose = null;

        // Reset the global search filter
        document.getElementById('search-input').value = '';
        filters.global = '';

        
        if (key === "productgroup" && value ==="basic lighting" )  {
            filters["purpose"] = "basic lighting" ;
        }   
        if ((  filters["productgroup"] === "combi" ) && (key === "purpose" && value === "anti panic combi" ) ) {
            filters["productgroup"] = "anti panic" 
        }   
        if ((  filters["productgroup"] === "combi" ) && (key === "purpose" && value === "escape route combi" ) ) {
            filters["productgroup"] = "escape route" 
        }   
          
        if (key === "purpose" && value === "wall bracket" )  {
            filters["purpose"] = "wall bracket" 
            filters["shape"] = "square" ;
            filters["wiring"] = "direct" ;
        }  
        if (key === "purpose" && value === "pendel" )  {
            filters["purpose"] = "pendel"; 
            filters["wiring"] = "direct" ;
        }   
        if (key === "purpose" && value === "ceiling box" )  {
            filters["purpose"] = "ceiling box" 
            filters["shape"] = "square" ;
            filters["wiring"] = "direct" ;
            filters["mounting"] = "surface" ;
        }  
        this.setState({filters: filters});
    }

    globalSearchHandler(event) {
        const filters = {
            ...this.state.filters,
            global: event.target.value.toLowerCase()
        };
        this.setState({filters: filters});
    }

    render() {
        const filteredData = this.filter();
        const sortedData = this.sort(filteredData);
        const {state} = this;
        const {intl} = this.props;

        return (
            <div className="app">
                <header className="app-header">
                    <div className="header-content">
                        <img src="/assets/logosmall.png" height="60" / > 
                        <FormattedMessage id="app.title" /> 
                        <div className="header-menu">
                            <input id="search-input" className="search-input" type="text" placeholder={intl.formatMessage({id: 'app.global search'})} onInput={this.globalSearchHandler} />
                            <div className="language-selector">
                                <a
                                    className={`language ${intl.locale === 'nl' ? 'disabled' : ''}`}
                                    href={intl.locale === 'nl' ? '#' : '?lang=nl'}>nl
                                    
                                </a>
                                &nbsp;
                                <a
                                    className={`language ${this.props.intl.locale === 'en' ? 'disabled' : ''}`}
                                    href={this.props.intl.locale === 'en' ? '#' : '?lang=en'}>en
                                    
                                </a>
                               
                            </div>
                        </div>
                    </div>
                </header>

                <div className={`filter-form ${state.filters.global.length > 0 ? "disabled" : ""}`}>
                    <section className="primary-groups">
                        <h3><FormattedMessage id="app.product-groups" /></h3>
                        <FilterRadioProductGroup filter='productgroup'
                                          values={[['exit sign', 'text to describe the exit sign ', 'https://products.evaqlighting.com/assets/drawings/2030_nd_salida_rond_in_basic_perspective.png'],
                                                  ['anti panic', 'text to describe the anti panic', 'https://products.evaqlighting.com/assets/drawings/2020_rond_in_antipaniek_top.png'],
                                                  ['escape route', 'text to describe the escape route', 'https://products.evaqlighting.com/assets/drawings/2025_rond_in_escape_route_top.png'],
                                                  ['combi', 'text to describe the combi', 'https://products.evaqlighting.com/assets/drawings/2034_nd_luna_basic_inbouw_sides.png'],
                                                  ['basic lighting', 'text to describe the combi', 'https://products.evaqlighting.com/assets/drawings/2034_nd_luna_basic_inbouw_sides.png'],
                                                  ['accessoires', 'text to describe the combi', 'https://products.evaqlighting.com/assets/drawings/2034_nd_luna_basic_inbouw_sides.png']]}
                                          state={state}
                                          setFilter={this.setFilter}
                        />
                        {state.filters.productgroup === 'exit sign' &&
                            <div> 
                                <h3><FormattedMessage id="app.params.exit sign" /></h3>
                                <FilterRadioProductGroup filter='purpose'
                                          values={[['exit sign basic','','https://products.evaqlighting.com/assets/drawings/2030_nd_salida_rond_in_basic_perspective.png'], ['exit sign plus','','https://products.evaqlighting.com/assets/drawings/2031_nd_salida_rond_in_ap_perspective.png'],[ 'Direction indicator','','https://products.evaqlighting.com/assets/drawings/2015_indicator_perspective.png']]}
                                          state={state}
                                          setFilter={this.setFilter}
                                />
                            </div>
                        }
                        {state.filters.productgroup === 'anti panic' &&
                            <div>
                                <h3><FormattedMessage id="app.params.anti panic" /></h3>
                                <FilterRadioProductGroup filter='purpose'
                                          values={[['standalone anti panic','','https://products.evaqlighting.com/assets/drawings/2020_rond_in_antipaniek_perspective.png'], ['anti panic combi','','https://products.evaqlighting.com/assets/drawings/2033_nd_luna_antipaniek_inbouw_perspective.png']]}
                                          state={state}
                                          setFilter={this.setFilter}
                                />
                            </div>
                        }
                        {state.filters.productgroup === 'escape route' &&
                            <div>
                                <h3><FormattedMessage id="app.params.escape route" /></h3>        
                                <FilterRadioProductGroup filter='purpose'
                                          values={[['standalone escape route'],['escape route combi']]}
                                          state={state}
                                          setFilter={this.setFilter}
                                />
                            </div>
                        }
                  
                        {state.filters.productgroup === 'combi' &&
                            <div>
                                <h3><FormattedMessage id="app.params.combi" /></h3>        
                                <FilterRadioProductGroup filter='purpose'
                                          values={[['anti panic combi'],[ 'escape route combi']]}
                                          state={state}
                                          setFilter={this.setFilter}
                                />
                            </div>
                        }
                        
                        {state.filters.productgroup === 'accessoires' &&
                            <div>
                                <h3><FormattedMessage id="app.params.accessoires" /></h3>   
                                <FilterRadioProductGroup filter='purpose'
                                          values={[['wall bracket'],['pendel'],['ceiling box']]}
                                          state={state}
                                          setFilter={this.setFilter}
                                />
                            </div>
                        }
                    </section>
            
                    {state.filters.purpose  &&        
                    <div>
                    <h3>Filters</h3>
                    <section className="labeled-groups">
                        {state.filters.productgroup !== "accessoires" &&    
                            <label className="label">
                                <FormattedMessage id="app.params.wiring" />
                            </label>
                        }
                        {state.filters.productgroup !== "accessoires"  &&    
                            <FilterRadioGroup filter='wiring'
                                          values={['direct', 'wieland']}
                                          state={state}
                                          setFilter={this.setFilter}
                            />
                        }
                        {state.filters.purpose !== "ceiling box" && <label className="label">
                            <FormattedMessage id="app.params.mounting" />
                        </label>
                        }
                         {state.filters.purpose !== "ceiling box" &&  
                        <FilterRadioGroup filter='mounting'
                                          values={['recessed', 'surface']}
                                          state={state}
                                          setFilter={this.setFilter}
                        />
                        }
                        <label className="label">
                            <FormattedMessage id="app.params.color" />
                        </label>
                        <FilterRadioGroup filter='color'
                                          values={['white', 'black', 'alu-grey']}
                                          state={state}
                                          setFilter={this.setFilter}
                        />
                         
                        {(state.filters.purpose !== "wall bracket" && state.filters.purpose !== "ceiling box" ) &&            
                            <label className="label">
                                <FormattedMessage id="app.params.shape" />
                            </label>
                         }
                       {(state.filters.purpose !== "wall bracket" && state.filters.purpose !== "ceiling box" ) &&       
                            <FilterRadioGroup filter='shape'
                                              values={['round','square' ]}
                                              state={state}
                                              setFilter={this.setFilter}
                            />
                            
                        }
                    </section>
                </div>
            }
        </div>
     {state.filters.purpose  &&    
               <div className="items-list">
                    <h3>EvaQ Lighting producten:</h3>
                        <div className="items-lists">
                            {sortedData.map(data => {
                                return (<SearchItem key={data.productNumber} data={data}/>)
                            })}
                        </div>

                {state.ready && sortedData.length === 0 &&
                    <div className="no-results">
                        <FormattedMessage id="app.no results" />
                    </div>
                }
                 </div>
            }
            </div>
        );
    }
}

App.propTypes = {
    intl: intlShape.isRequired,
};
export default injectIntl(App);
