import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {addLocaleData, IntlProvider} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import nlLocaleData from 'react-intl/locale-data/nl';
import deLocaleData from 'react-intl/locale-data/de';
import messages_en from "./translations/en.json";
import messages_nl from "./translations/nl.json";
import messages_de from "./translations/de.json";

const messages = {
    'en': messages_en,
    'nl': messages_nl,
    'de': messages_de
};
let language;
try {
    // Try to get language from URL
    const URLparams = document.location.search.split("?")[1].split("&");
    language = URLparams.filter(param => param.indexOf("lang") !== -1)[0].split("=")[1];
}
catch {
    // Detect the language from locale
    language = navigator.language.split(/[-_]/)[0];
}

if(language !== 'en' && language !== 'nl' && language !== 'de') {
    // Fallback for unsupported languages
    language = 'nl';
}

addLocaleData([...enLocaleData, ...nlLocaleData, ...deLocaleData]);

ReactDOM.render(
    <IntlProvider locale={language} messages={messages[language]}>
        <App />
    </IntlProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
