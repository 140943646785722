import React, { Component } from 'react';
import styles from './SearchItem.module.css';
import {FormattedMessage} from "react-intl";

export default class SearchItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: true,
            isDetailsExpanded: false
        };

        this.toggleViewMode = this.toggleViewMode.bind(this);
        this.toggleDetails = this.toggleDetails.bind(this);
    }

    toggleViewMode() {
        this.setState({isExpanded: !this.state.isExpanded});
    }

    toggleDetails() {
        this.setState({isDetailsExpanded: !this.state.isDetailsExpanded});
    }

    render() {
        const {data} = this.props;

        if (this.state.isExpanded) {
            return (
                <div className={styles.item}>
                    <div className={styles.header} >
                        {data.fixture} {data.productNumber}
                        <span className={styles.extraheader}>
                          
                        </span>
                        <div className={styles.subheader}>
                        <br/>
                            <FormattedMessage id={`app.product.description.${data.productNumber}`} />
                            
                        </div>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.key}>
                            <FormattedMessage id="app.params.color" />
                        </div>
                        <div className={styles.value}>
                            <FormattedMessage id={`app.params.${data.color}`} />
                        </div>
                        {(data.purpose !== "wall bracket"  && data.purpose !== "ceiling box" )   && <div className={styles.key}>
                            <FormattedMessage id="app.params.shape" />
                        </div>
                        }
                        {(data.purpose !== "wall bracket"  && data.purpose !== "ceiling box" )   && <div className={styles.value}>
                            <FormattedMessage id={`app.params.${data.shape}`} />
                        </div>
                        }
                        {( data.purpose !== "ceiling box" )   && <div className={styles.key}>
                            <FormattedMessage id="app.params.mounting" />
                        </div>
                        }
                        {( data.purpose !== "ceiling box" )   && 
                        <div className={styles.value}>
                            <FormattedMessage id={`app.params.${data.mounting}`} />
                        </div>
                        }
                        {(data.purpose !== "wall bracket" && data.purpose !== "pendel" && data.purpose !== "ceiling box" )  && 
                            <div className={styles.key}>
                                <FormattedMessage id="app.params.wiring" />
                            </div>
                        }
                        {(data.purpose !== "wall bracket" && data.purpose !== "pendel" && data.purpose !== "ceiling box" )  && 
                            <div className={styles.value}>
                                <FormattedMessage id={`app.params.${data.wiring}`} />
                            </div>
                        }
                        {
                            (data.mechanicalmodel && data.mechanicalmodel._parameters && data.mechanicalmodel._parameters.length) ?
                                data.mechanicalmodel._parameters.map(item => {
                                    return [
                                        <div className={`${styles.key} ${!this.state.isDetailsExpanded ? styles.hidden : ''}`} key={`${item.id}-key`}>
                                            {
                                                item.key.length ?
                                                    <FormattedMessage id={`app.params.${item.key}`} />
                                                    : ''
                                            }
                                        </div>,
                                        <div className={`${styles.value} ${!this.state.isDetailsExpanded ? styles.hidden : ''}`} key={`${item.id}-value`}>
                                            {
                                                item.value.length ?
                                                    <FormattedMessage id={`app.params.${item.value}`} />
                                                    : ''
                                            }
                                        </div>
                                    ]
                                }
                            ) : null
                        }
                    </div>
                    {
                        (data.mechanicalmodel && data.mechanicalmodel._parameters && data.mechanicalmodel._parameters.length) ?
                        <div className={styles.detailsFooter}>
                            <a className={styles.toggle} onClick={this.toggleDetails} href="#">
                                {this.state.isDetailsExpanded ? 'Hide details ˄' : 'Show more details ˅'}
                            </a>
                        </div>
                        : null
                    }
                    {
                        data.mechanicalmodel && data.mechanicalmodel._downloads && data.mechanicalmodel._downloads.length > 0 &&
                        <div className={styles.downloads}>
                            <div className={styles.title}>
                                <FormattedMessage id="app.labels.datasheet documents" />:
                            </div>
                            {
                                data.mechanicalmodel._downloads.map(item => {
                                    return (
                                        <div key={item.id}>
                                            <a className={styles.download} target='_blank' rel='noopener noreferrer' href={"https://products.evaqlighting.com"+ item.URL} >
                                                {item.label}  ({item.language})
                                            </a>
                                            <br/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        data.mechanicalmodel && data.mechanicalmodel._slides &&
                        <div className={styles.images}>
                            {
                                data.mechanicalmodel._slides.map(item => {
                                    return (
                                        <div className={styles.imageWrapper} key={item.id}>
                                            <img className={styles.image} alt={item.label +" " +item.category} src={"https://products.evaqlighting.com"+ item.URL} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
          
                </div>
            )
        } else {
            return (
                <div className={styles.item} onClick={this.toggleViewMode}>
                    <div className={styles.header} onClick={this.toggleViewMode}>
                        {data.fixture} {data.productNumber}
                        <span className={styles.extraheader}>
                            &nbsp;(<FormattedMessage id={`app.params.${data.mounting}`} />, <FormattedMessage id={`app.params.${data.placement}`} />)
                        </span>
                        <div className={styles.subheader}>
                            <br/>
                            <FormattedMessage id={`app.product.description.${data.productNumber}`} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}
