import React, { Component } from 'react';
import styles from './FilterNoRadioGroup.module.css';
import {FormattedMessage} from 'react-intl';

export default class FilterNoRadioGroup extends Component {
    render() {
        const { state, values,  filter, setFilter } = this.props;

        return (
            <div className={styles.filterGroup}>
                {
                    values.map((value) => {
                        return (
                            <button
                                onClick={() => setFilter(filter, value)}
                                className={`${styles.button}
                                            ${state.filters[filter] === value ? styles.active : ''}`}
                                key={value}
                            >
                                <FormattedMessage id={`app.params.${value}`} />
                            </button>
                        )
                    })
                }
            </div>
        );
    }
}
