import React, { Component } from 'react';
import styles from './FilterRadioIconGroup.module.css';
import {FormattedMessage} from 'react-intl';

export default class FilterRadioIconGroup extends Component {
    render() {
        const { state, values,  filter, setFilter } = this.props;

        return (
            <div className={styles.filterGroup}>
                {
                    values.map((value) => {
                        return (
                            <button
                                onClick={() => setFilter(filter, value[0])}
                                className={`${styles.button}
                                            ${state.filters[filter] === value[0] ? styles.active : ''}`}
                                key={value[0]}
                            >
                               <i  className={'far fa-'+value[1] +' '+ styles.icon}></i> <FormattedMessage id={`app.params.${value[0]}`} />
                            </button>
                        )
                    })
                }
            </div>
        );
    }
}
